<template>
  <div class="">
    <select
      name=""
      class="border-b-[1px] border-gray-600 border-dashed w-full h-12 text-gray-500 cursor-pointer my-1 lg:w-60 lg:h-12"
      @change="selectValue"
    >
      <option v-for="option in options" :key="option" :disabled="isDisabled(option)">
        {{option}}
      </option>
    </select>
    <template v-for="val in selectedOptionsData" :key="val">
      <div
          @click="unselectValue(val)"
          class="w-full bg-blue text-blue-600 hover:bg-indigo-700 hover:text-white text-l cursor-pointer uppercase my-2 lg:w-60 text-sm"
      >
        {{ val }}
        <span class="rightAlignedIcon">x</span>
      </div>
    </template>

  </div>
</template>

<script>
import {ref, watchEffect} from "vue";

export default {
  name: "SelectButton",
  props: {
    data: {
      type: Array,
      required: true,
    },
  },
  emits : ["selectedOptions"],
  setup(props, {emit}) {
    const options = ref(props.data);
    const selectedOptionsData = ref([]);
    const updateOptions = () => {
      const hasOptions = Object.keys(props.data).length;
      if (!hasOptions) {
        console.log("NO OPTION PROVIDED")
      }
      else {
        options.value = props.data;
      }
    }
    const selectValue = (event) => {
      if (!event.target.value.startsWith("Filter By")) {
        selectedOptionsData.value.push(event.target.value)
        emit("selectedOptions", selectedOptionsData);
      }
    }
    const unselectValue = (val) => {
      console.log("unselect", val, selectedOptionsData.value.indexOf(val))
      selectedOptionsData.value = selectedOptionsData.value.filter(v => v !== val)
      emit("selectedOptions", selectedOptionsData);
    }
    const isDisabled = (val) => {
      return selectedOptionsData.value.includes(val);
    }
    watchEffect(updateOptions);
    return {
      options,
      selectedOptionsData,
      selectValue,
      unselectValue,
      isDisabled,
    }
  }
};
</script>
