<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    aria-hidden="true"
    fill="currentColor"
    viewBox="0 0 24 21"
    class=""
  >
    <g id="Layer_2" data-name="Layer 2">
      <path
        class="cls-1"
        d="M18.69,7.66a1.18,1.18,0,0,0-.08.16l-1.29,2.53a.90.90,0,1,1-1.62-.78c.30-.61.61-1.21.91-1.81C17.07,6.88,17.52,6,18,5.10a.92.92,0,0,1,.73-.53,1,1,0,0,1,.53.11q2.13,1.08,4.25,2.17a.85.85,0,0,1,.49.87.86.86,0,0,1-.61.78.87.87,0,0,1-.72-.06l-2-1-.16-.08a1.43,1.43,0,0,0,.05.18A9.9,9.9,0,0,1,21,10.44a10.27,10.27,0,0,1-2.74,7.11,10.12,10.12,0,0,1-6.08,3.28,10.25,10.25,0,0,1-7.40-1.57c-.26-.16-.49-.38-.74-.57a8.79,8.79,0,0,1-.74-.60A10.4,10.4,0,0,1,.20,12.52,10.45,10.45,0,0,1,2.50,3.71,10.32,10.32,0,0,1,8.76,.15a10,10,0,0,1,3.66,0,.88.88,0,0,1,.71,1.05A.9.9,0,0,1,12.07,2a8.45,8.45,0,0,0-2.3-.11,8.41,8.41,0,0,0-4,1.35A8.47,8.47,0,0,0,2,8.57a8.57,8.57,0,0,0,.75,5.90A8.7,8.7,0,0,0,9.36,19.10a8.44,8.44,0,0,0,6.24-1.60,8.48,8.48,0,0,0,3.46-5.64,8.55,8.55,0,0,0-.31-4Z"
      />
    </g>
  </svg>
</template>
