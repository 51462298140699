<template>
  <div class="lProgramCard">
    <div
      class="card-cols flex flex-col k12:flex-row justify-between bg-gray-100 hover:bg-indigo-50 p-8 group cursor-pointer relative"
      :class="{ 'pt-12': program.isInternship }"
      @click="
        $router.push({
          name: 'qtEduResource',
          params: { title: program.title },
        })
      "
    >
      <div
        class="flex gap-4 items-center pb-8 absolute -top-1"
        v-if="program.isInternship"
      >
        <div class="w-6 h-8 intern"></div>
        <p class="text-sm">INTERNSHIP OPPORTUNITY</p>
      </div>
      <div class="left-Col w-full k12:w-[70%] space-y-4 mb-4 lg:mb-0">
        <div class="DataType">
          <span class="text-indigo-950 font-semibold text-sm uppercase">
            <p v-if="program.isOnline" class="inline-block pr-1">Online</p>
            <p class="inline-block font-light">
              {{ program.category }}
            </p>
          </span>
        </div>
        <h3 class="Title font-semibold text-2xl group-hover:text-indigo-700">
          {{ program.title }}
        </h3>
        <div class="content">
          <p>{{ program.summary }}</p>
        </div>
        <div class="Target">
          <div class="flex gap-x-12 gap-y-3 flex-wrap">
            <p
              class="flex items-center gap-2"
              v-for="target in program.target"
              :key="target"
            >
              <img
                :src="'https://qtedu.eu/img/target.9c56fd60.svg'"
                alt=""
                class="w-5 h-6"
              />
              {{ target }}
            </p>
          </div>
        </div>
      </div>
      <div
        class="right-Col k12:w-[25%] border-t-2 pt-4 k12:border-l-2 k12:border-t-0 k12:pl-4 border-dashed border-gray-300 space-y-2"
      >
        <div class="" v-if="program.country">
          <div class="text-gray-400 text-sm uppercase">Country / Area</div>
          <p class="font-semibold text-sm capitalize">{{ program.country }}</p>
        </div>

        <div v-if="program.lang.length" class="languages">
          <p class="text-gray-400 text-sm uppercase">Languages</p>
          <p
            class="font-semibold text-sm"
            v-for="language in program.lang"
            :key="language"
          >
            {{ language }}
          </p>
        </div>
        <div class="">
          <div class="text-gray-400 text-sm uppercase">Provided By</div>
          <!--        <div class="provided">
          <p class="font-semibold text-sm capitalize">
            {{ program.org }} {{ program.area }}
          </p>
        </div>-->

          <div class="provided">
            <p
              class="font-semibold text-sm capitalize"
              v-for="provider in program.org"
              :key="provider"
            >
              {{ provider }}
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "LProgramprogram",
  props: {
    program: {
      type: Object,
      required: true,
    },
  },
};
</script>

<style scoped></style>
